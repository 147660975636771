<template>
  <q-page class="column">
    <div class="col-grow column q-pa-md" style="height:0">
      <div class="col-auto q-mb-md">
        <q-form class="search-form row q-gutter-md">
          <q-select
            label="查询字段"
            v-model="param.fieldName"
            :options="options"
            @filter="filterFn"
            :use-input="!param.fieldName"
            dense
            clearable
            outlined
          />
          <q-input
            v-model="param.fieldContent"
            label="查询内容"
            placeholder="请输入查询内容"
            dense
            clearable
            outlined
          />
          <q-btn label="搜索" icon="search" type="submit" color="primary" unelevated @click="loadData"/>
          <q-btn label="生成选中的材料" icon="play_for_work" color="primary" outline @click="generateContract"/>
        </q-form>
      </div>
      <div class="col" style="height:0;width:100%;">
        <div class="full-width full-height" style="overflow:hidden;">
          <q-markup-table flat bordered class="field-table fixed-first-row" style="max-height:100%">
            <colgroup>
              <col style="width:80px"/>
              <col/>
              <col/>
            </colgroup>
            <thead>
            <tr>
              <th class="text-left">
                <q-checkbox :model-value="checkall" @update:model-value="updateCheckAll"/>
              </th>
              <th class="text-left">材料名称</th>
              <th class="text-left">字段内容</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in data" :key="index">
              <td class="text-left">
                <q-checkbox v-model="selection" :val="`${item.formId}-${item.__id__}`"/>
              </td>
              <td class="text-left">{{ item.formName }}</td>
              <td class="text-left">{{ item.fieldContent }}</td>
            </tr>
            </tbody>
          </q-markup-table>
        </div>
      </div>
    </div>
  </q-page>
  <teleport to="body">
    <q-dialog v-model="showDownloadForm" persistent>
      <q-card style="width:470px;max-width:90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">材料下载</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <div v-for="(item,index) in files" :key="index" class="row items-center q-mb-sm">
            <span class="q-mr-sm">{{ item.formName }}</span>
            <q-spinner-oval v-if="!item.url" color="primary" size="2em"/>
            <span v-else-if="item.url === 'error'" class="text-negative">生成失败</span>
            <a v-else :href="item.url" target="_blank">下载</a>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </teleport>
</template>

<script>
import { getCurrentInstance, nextTick, ref } from 'vue'

export default {
  setup () {
    const { proxy } = getCurrentInstance()
    const fields = ref([])
    const options = ref([])
    const data = ref([])
    const checkall = ref(false)
    const selection = ref([])
    const files = ref([])
    const param = ref({
      fieldName: null,
      fieldContent: null,
      fieldOp: 1
    })
    const showDownloadForm = ref(false)

    function updateCheckAll (value) {
      checkall.value = value
      if (value) {
        selection.value = data.value.map((item) => `${item.formId}-${item.__id__}`)
      } else {
        selection.value = []
      }
    }

    function filterFn (val, update) {
      if (val === '') {
        update(() => {
          options.value = fields.value
        })
        return
      }
      update(() => {
        const needle = val.toLowerCase()
        options.value = fields.value.filter(v => v.toLowerCase().indexOf(needle) > -1)
      })
    }

    async function loadFields () {
      fields.value = await proxy.$server.post('/contractFormField/allContractFormFieldList')
      options.value = fields.value
    }

    async function loadData () {
      checkall.value = false
      selection.value = []
      data.value = await proxy.$server.post('/contractFormData/contractGlobalFormDataList', param.value)
    }

    async function generateContract () {
      const values = selection.value
      if (values.length === 0) {
        proxy.$message.tipsWarning('请选择要生成的材料')
        return
      }
      const map = {}
      const list = []
      values.forEach((item) => {
        const splitVal = item.split('-')
        const formId = splitVal[0]
        const id = splitVal[1]
        if (!map[formId]) {
          const formName = data.value.find((item) => item.formId === formId && item.__id__ === id).formName
          list.push(map[formId] = { formId, formName, ids: [], url: null })
        }
        map[formId].ids.push(id)
      })
      files.value = list
      showDownloadForm.value = true
      nextTick(() => {
        for (const item of files.value) {
          (async (item) => {
            try {
              item.url = await proxy.$server.post(
                '/contractFormData/generateContract',
                item,
                { timeout: 30 * 60 * 1000, loading: false }
              )
            } catch (e) {
              item.url = 'error'
            }
          })(item)
        }
      })
    }

    loadFields()

    return {
      data,
      fields,
      options,
      param,
      checkall,
      selection,
      files,
      updateCheckAll,
      loadData,
      filterFn,
      generateContract,
      showDownloadForm
    }
  }
}
</script>
